import React from 'react';
import { Box } from '@components/Primitives';
import { BlueBox } from '@components/BlueBox';
import styled from 'styled-components';
import { Link, Text } from '@primitives';
import theme from '../../styles';
import { screens } from '../../styles/theme';

interface Props {
  contactInformation: IContactInformation[];
}

const ContactsGrid = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  @media screen and (max-width: ${screens.md}px) {
    grid-template-columns: 100%;
  }
`;


const ContactInfo = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: ${theme.typography.fontSizes.md};
  a {
    justify-content: center;
    margin-bottom: 5px;
  }
  p {
    margin: 0 !important;
    margin-bottom: 1rem;
  }
`;

const ContactInformationSection: React.FC<Props> = ({
  contactInformation
}) => {
  return (
    <ContactsGrid>
      {contactInformation && contactInformation.map(({
        contact_type, title, address, postal_number, postal_office, description,
        email, homepage_url, phone_number, name,
      }) => {
        return (
          <BlueBox title={title || contact_type} key={`${contact_type} ${title}`} p="3">
            <Text mb="1">{name}</Text>
            <Text mb="1" fontSize="sm">{description}</Text>
            {address && (
              <ContactInfo mb="2">
                {address && <Text>{address}</Text>}
                {(!!postal_number || !!postal_office) && (<Text>{postal_number} {postal_office}</Text>)}
              </ContactInfo>
            )}

            <ContactInfo>
              {email && <Text><Link href={`mailto:${email}`}>{email}</Link></Text>}
              {phone_number && <Text><Link href={`tel:${phone_number}`}>{phone_number}</Link></Text>}
              {homepage_url && <Text><Link href={homepage_url}>{homepage_url}</Link></Text>}
            </ContactInfo>
          </BlueBox>
        );
      })}
    </ContactsGrid>
  );
}


export { ContactInformationSection };
