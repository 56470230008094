/* eslint-disable react/no-danger */
import React, { FC, useEffect } from "react";
import { useStores } from "@stores";
import { observer } from "mobx-react";
import { graphql } from "gatsby";
import { TextSection } from "@components/TextSection";
import { ContentPageLayout } from "@components/Layout";
import { ContactInformationSection } from "@components/ContactInformationSection/ContactInformationSection";
import { Box, Spinner } from "@components/Primitives";
import { NaviButtons } from "@components/NaviButtons";
import { PAGE_NAV_ITEMS } from "../../constants/page-nav-items";
import { FormattedMessage as M } from "gatsby-plugin-intl";

interface IMaintenancePage {
  data: IMarkdownData;
}

const MaintenancePage: FC<IMaintenancePage> = observer(
  ({
    data: {
      markdownRemark: {
        frontmatter: { blocks: texts },
      },
    },
  }) => {
    const {
      commonDataStore: { getContacts, state, contactInformation },
      agreementStore: { selectedAgreement },
    }: {
      commonDataStore: ICommonDataStore;
      agreementStore: IAgreementStore;
    } = useStores();

    const isLoading = state === "Loading";

    useEffect(() => {
      if (!isLoading && !contactInformation) {
        getContacts();
      }
    }, [selectedAgreement]);

    if (!contactInformation) return <ContentPageLayout />;

    const getTexts = (id: string) => texts.find((block) => block.id === id);

    const renderSection = (id: string, blocks: any) => (
      <>
        <Box mt="2" mb="2">
          <TextSection
            level="2"
            alignment="center"
            title={getTexts(id)?.title}
            text={getTexts(id)?.text}
          />
        </Box>

        {selectedAgreement && selectedAgreement.estate && (
          <ContactInformationSection contactInformation={blocks} />
        )}
      </>
    );

    return (
      <ContentPageLayout wideContent>
        <TextSection
          title={<M id="fields.label.maintenance.title" />}
          text={<M id="fields.label.maintenance.text" />}
          afterTitle={<NaviButtons items={PAGE_NAV_ITEMS.maintenance} />}
        />
        {isLoading && <Spinner color="mainBlue" />}
        {!isLoading && (
          <Box>
            {renderSection(
              "maintenance",
              selectedAgreement?.estate.maintenance_contacts
            )}
          </Box>
        )}
      </ContentPageLayout>
    );
  }
);

export const pageQuery = graphql`
  query ContactMaintenanceQuery {
    markdownRemark(frontmatter: { path: { eq: "/contact/maintenance/" } }) {
      frontmatter {
        path
        blocks {
          title
          text
          id
        }
      }
    }
  }
`;

export default MaintenancePage;
